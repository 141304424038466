<template>
  <div class="gov">
    <div class="container">
      <AddressPosition></AddressPosition>
      <div class="main">
        <ImgTxtCard :data="topCardData"></ImgTxtCard>
        <TimeLine :list="govList"></TimeLine>
        <el-pagination style="text-align: center;margin-bottom: 60px;"
          background
          layout="prev, pager, next, total, jumper"
          prev-text="上一页"
          next-text="下一页"
          :page.sync="page"
          @current-change="paginationInitList"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import bgIMG from '@/assets/banner-zhengwu.jpg'
import AddressPosition from '@/components/AddressPosition'
import ImgTxtCard from '@/components/ImgTxtCard.vue'
import TimeLine from '@/components/TimeLine.vue'
// import imgSrc from '@/utils/img.js'
// const demoIMG = imgSrc.data()

import { NewsList } from '@/api'

export default {
  name: 'Government',
  components: {
    AddressPosition,
    ImgTxtCard,
    TimeLine,
    total: 1
  },
  data() {
    return {
      // activeIndex: '/government'
      // bgIMG,
      // cover: demoIMG.HANGYE1,
      topCardData: {},
      govList: [],
      page: 1,
      limit: 10,
      total: 0
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    this.getNewList()
  },
  methods: {
    getNewList() {
      NewsList({
        direction: 'desc',
        pageNum: this.page,
        pageSize: this.limit,
        newsType: '1',
        // newsState: '1'
      }).then(res => {
        let { content } = res.data
        content.map(item=>{
          if (item.publishedTime)
          item.publishedTime = item.publishedTime.split(' ')[0]
          if (item.newsCover.indexOf('http')==-1) {
            item.newsCover = `http://img.miaocang.cc/${item.newsCover}`
          }
          return item
        })
        // 政务列表
        this.topCardData = {...content[0]}
        this.govList = content
        this.total = res.data.total
      })
    },
    paginationInitList(val) {
      this.page = val
      this.getNewList()
    }
  }
}
</script>

<style lang="less">
.gov {
  &>.container {
    width: 1200px;
    min-height: 1000px; /**待优化 */
    margin: 0 auto;
  }
}
</style>
